export const menu = [
  {
    // APPETIZERS
    menuSectionName: "APPETIZERS - 头盘(前菜)",
    menuSectionItems: [
      {
        id: 1,
        name: "Three Kind Appetizer Combinations",
        price: 39.80,
        isSpicy: true,
        menuItemNumber: 1,
        chineseTranslation: "椒盐三拼",
      },
      {
        id: 2,
        name: "New King Do Appetizer Combinations",
        price: 56.80,
        isSpicy: false,
        menuItemNumber: 2,
        chineseTranslation: "新京都大拼盘",
      },
      {
        id: 3,
        name: "Braised Crispy Squab",
        price: -1, // -1 is considered as market price
        isSpicy: false,
        menuItemNumber: 3,
        chineseTranslation: "红烧乳鸽王",
      },
      {
        id: 4,
        name: "D.F. Crab Claws Wrapper with Minced Shrimp",
        price: 9.25,
        isSpicy: false,
        menuItemNumber: 4,
        chineseTranslation: "白花酿蟹钳",
      },
      {
        id: 5,
        name: "Cold Marinated Jellyfish and Pork Hock",
        price: 26.95,
        isSpicy: false,
        menuItemNumber: 5,
        chineseTranslation: "海蜇熏蹄",
      },
      {
        id: 6,
        name: "Deep-Fried Assorted Meat Combinations",
        price: 39.80,
        isSpicy: false,
        menuItemNumber: 6,
        chineseTranslation: "酥炸拼盘",
      },
      {
        id: 7,
        name: "Lettuce Wrap (Chicken)",
        price: 29.95,
        isSpicy: false,
        menuItemNumber: 7,
        chineseTranslation: "生菜包 (鸡)",
      },
      {
        id: 8,
        name: "Lettuce Wrap (Beef)",
        price: 29.95,
        isSpicy: false,
        menuItemNumber: 7,
        chineseTranslation: "生菜包 (牛肉)",
      },
      {
        id: 9,
        name: "Lettuce Wrap (Seafood)",
        price: 33.95,
        isSpicy: false,
        menuItemNumber: 7,
        chineseTranslation: "生菜包 (海鲜)",
      },
      {
        id: 10,
        name: "Spring Roll (1pc)",
        price: 1.95,
        isSpicy: false,
        menuItemNumber: 8,
        chineseTranslation: "炸春卷",
      },
      {
        id: 11,
        name: "Deep-Fried Wonton",
        price: 13.50,
        isSpicy: false,
        menuItemNumber: 9,
        chineseTranslation: "炸云吞",
      },
      {
        id: 12,
        name: "B.B.Q Pork",
        price: 14.95,
        isSpicy: false,
        menuItemNumber: 10,
        chineseTranslation: "蜜汁叉烧",
      },
    ],
  },
  {
    menuSectionName: "SOUPS - 汤羹类",
    menuSectionItems: [
      {
        id: 13,
        name: "Szechuan Hot-and-Sour Soup (S)",
        price: 13.95,
        isSpicy: true,
        menuItemNumber: 11,
        chineseTranslation: "四川酸辣汤",
      },
      {
        id: 14,
        name: "Crabmeat with Fish Maw Soup (S)",
        price: 16.80,
        isSpicy: false,
        menuItemNumber: 12,
        chineseTranslation: "蟹肉鱼肚羹",
      },
      {
        id: 15,
        name: "Crabmeat with Fish Maw Soup (L)",
        price: 29.80,
        isSpicy: false,
        menuItemNumber: 12,
        chineseTranslation: "蟹肉鱼肚羹",
      },
      {
        id: 16,
        name: "Crabmeat with Cream Corn Soup",
        price: 15.50,
        isSpicy: false,
        menuItemNumber: 13,
        chineseTranslation: "蟹肉粟米羹",
      },
      {
        id: 17,
        name: "Minced Chicken and Cream Corn Soup",
        price: 13.50,
        isSpicy: false,
        menuItemNumber: 14,
        chineseTranslation: "鸡茸素米羹",
      },
      {
        id: 18,
        name: "Wonton Soup",
        price: 14.95,
        isSpicy: false,
        menuItemNumber: 15,
        chineseTranslation: "鲜虾云吞汤",
      },
      {
        id: 19,
        name: "War Wonton Soup (Large)",
        price: 26.95,
        isSpicy: false,
        menuItemNumber: 16,
        chineseTranslation: "锅云吞",
      },
      {
        id: 20,
        name: "Minced Beef and Egg White Soup",
        price: 14.95,
        isSpicy: false,
        menuItemNumber: 17,
        chineseTranslation: "西湖牛肉羹",
      },
      {
        id: 21,
        name: "Seafood and Dry Scallop Soup",
        price: 16.80,
        isSpicy: false,
        menuItemNumber: 18,
        chineseTranslation: "瑶柱海黄羹",
      },
      {
        id: 22,
        name: "Assorted Seafood and Tofu Soup (Large)",
        price: 24.95,
        isSpicy: false,
        menuItemNumber: 19,
        chineseTranslation: "海鲜豆腐汤",
      },
    ],
  },
  {
    menuSectionName: "BEEF AND PORK - 牛，猪肉类",
    menuSectionItems: [
      {
        id: 23,
        name: "Sliced Pork Sauteed with Cabbage",
        price: 18.95,
        isSpicy: true,
        menuItemNumber: 20,
        chineseTranslation: "回锅肉",
      },
      {
        id: 24,
        name: "Shredded Pork with Hot Garlic Sauce",
        price: 17.95,
        isSpicy: true,
        menuItemNumber: 21,
        chineseTranslation: "鱼香肉丝",
      },
      {
        id: 25,
        name: "Sweet-and-Sour Pork with Pineapple",
        price: 17.95,
        isSpicy: false,
        menuItemNumber: 22,
        chineseTranslation: "菠萝甜酸猪",
      },
      {
        id: 26,
        name: "Sweet-and-Sour Pork (Boneless)",
        price: 17.95,
        isSpicy: false,
        menuItemNumber: 23,
        chineseTranslation: "咕噜肉",
      },
      {
        id: 27,
        name: "Honey Garlic Boneless Pork",
        price: 17.95,
        isSpicy: false,
        menuItemNumber: 24,
        chineseTranslation: "蜜味蒜子肉",
      },
      {
        id: 28,
        name: "Mandarin Pork Chops",
        price: 22.95,
        isSpicy: false,
        menuItemNumber: 25,
        chineseTranslation: "京都肉排",
      },
      {
        id: 29,
        name: "Deep-Fried Spicy Pork Chops",
        price: 21.95,
        isSpicy: true,
        menuItemNumber: 26,
        chineseTranslation: "椒盐肉排",
      },
      {
        id: 30,
        name: "Dry Garlic Spareribs",
        price: 17.95,
        isSpicy: false,
        menuItemNumber: 27,
        chineseTranslation: "蒜子干骨",
      },
      {
        id: 31,
        name: "Deep-Fried Spicy Dry Spareribs",
        price: 17.95,
        isSpicy: true,
        menuItemNumber: 28,
        chineseTranslation: "椒盐干骨",
      },
      {
        id: 32,
        name: "Steamed Spareribs in Black Bean Sauce",
        price: 21.95,
        isSpicy: false,
        menuItemNumber: 29,
        chineseTranslation: "豉汁蒸排骨",
      },
      {
        id: 33,
        name: "Steamed Pork Patty with Salted Fish",
        price: 24.95,
        isSpicy: false,
        menuItemNumber: 30,
        chineseTranslation: "咸鱼蒸肉饼",
      },
      {
        id: 34,
        name: "Sliced Beef with Black Bean Sauce",
        price: 18.95,
        isSpicy: false,
        menuItemNumber: 31,
        chineseTranslation: "豉汁牛肉",
      },
      {
        id: 35,
        name: "Stir-Fried Beef with Green Onions",
        price: 18.95,
        isSpicy: false,
        menuItemNumber: 32,
        chineseTranslation: "姜葱牛肉",
      },
      {
        id: 36,
        name: "Beef with Satay Sauce",
        price: 18.95,
        isSpicy: false,
        chineseTranslation: "沙爹牛肉",
        menuItemNumber: 33,
      },
      {
        id: 37,
        name: "Curried Beef",
        price: 18.95,
        isSpicy: true,
        chineseTranslation: "咖喱牛肉",
        menuItemNumber: 34,
      },
      {
        id: 38,
        name: "Pan-Fried Beef Tenderloin with Gourmet Sauce",
        price: 23.95,
        isSpicy: false,
        chineseTranslation: "中式牛柳",
        menuItemNumber: 35,
      },
      {
        id: 39,
        name: "Pan-Fried Beef Tenderloin with Black Pepper Sauce",
        price: 24.95,
        isSpicy: true,
        chineseTranslation: "黑椒牛柳",
        menuItemNumber: 36,
      },
      {
        id: 40,
        name: "Shredded Beef with Ginger Sauce",
        price: 18.95,
        isSpicy: false,
        chineseTranslation: "姜汁牛柳丝",
        menuItemNumber: 37,
      },
      {
        id: 41,
        name: "Mongolian Beef",
        price: 18.95,
        isSpicy: false,
        chineseTranslation: "蒙古牛肉",
        menuItemNumber: 38,
      },
      {
        id: 42,
        name: "Beef with Broccoli",
        price: 17.95,
        isSpicy: false,
        chineseTranslation: "百家利牛肉",
        menuItemNumber: 39,
      },
      {
        id: 43,
        name: "Beef with Gai Lan (Chinese Broccoli)",
        price: 24.95,
        isSpicy: false,
        chineseTranslation: "芥蘭牛肉",
        menuItemNumber: 40,
      },
      {
        id: 44,
        name: "Stir-Fried Pork Belly with Bell Peppers",
        price: 19.95,
        isSpicy: false,
        chineseTranslation: "农家小炒肉",
        menuItemNumber: 41,
      },
      {
        id: 45,
        name: "Beef with Oyster Sauce",
        price: 17.95,
        isSpicy: false,
        chineseTranslation: "蚝油牛肉",
        menuItemNumber: 42,
      },
      {
        id: 46,
        name: "Beef with Tomatoes",
        price: 17.95,
        isSpicy: false,
        chineseTranslation: "番茄牛肉",
        menuItemNumber: 43,
      },
      {
        id: 47,
        name: "Beef with Snow Peas",
        price: 19.95,
        isSpicy: false,
        chineseTranslation: "雪豆牛肉",
        menuItemNumber: 44,
      },
      {
        id: 48,
        name: "Beef Brisket with Daikon",
        price: 24.80,
        isSpicy: false,
        chineseTranslation: "萝卜牛腩",
        menuItemNumber: 45,
      },
      {
        id: 49,
        name: "Mixed Vegetable and Beef",
        price: 18.95,
        isSpicy: false,
        chineseTranslation: "杂菜牛肉",
        menuItemNumber: 46,
      },
      {
        id: 50,
        name: "Beef in Chili Sauce",
        price: 23.80,
        isSpicy: true,
        chineseTranslation: "水煮牛肉",
        menuItemNumber: 47,
      },
      {
        id: 51,
        name: "Sliced Beef with Green Bean in Black Bean Sauce",
        price: 20.95,
        isSpicy: false,
        chineseTranslation: "豉汁四季豆牛肉",
        menuItemNumber: 48,
      },
    ],
  },
  {
    menuSectionName: "CHICKEN AND DUCK - 鸡，鸭肉类",
    menuSectionItems: [
      {
        id: 52,
        name: "Szechuan Chicken",
        price: 17.95,
        isSpicy: true,
        chineseTranslation: "四川鸡",
        menuItemNumber: 49,
      },
      {
        id: 53,
        name: "Curried Chicken",
        price: 18.50,
        isSpicy: true,
        chineseTranslation: "咖喱鸡",
        menuItemNumber: 50,
      },
      {
        id: 100,
        name: "Spicy Chicken",
        price: 22.95,
        isSpicy: true,
        chineseTranslation: "辣子鸡",
        menuItemNumber: 51,
      },
      {
        id: 54,
        name: "Sliced Chicken with XO Sauce",
        price: 18.95,
        isSpicy: false,
        chineseTranslation: "京都鸡柳",
        menuItemNumber: 52,
      },
      {
        id: 55,
        name: "Deep Fried Chicken Wing",
        price: 16.95,
        isSpicy: false,
        chineseTranslation: "炸鸡翼",
        menuItemNumber: 53,
      },
      {
        id: 56,
        name: "Deep Fried Chicken Wings with Peppery Salt",
        price: 17.95,
        isSpicy: true,
        chineseTranslation: "椒盐鸡翼",
        menuItemNumber: 54,
      },
      {
        id: 57,
        name: "Deep Fried Breaded Almond Chicken",
        price: 18.95,
        isSpicy: false,
        chineseTranslation: "杏仁酥鸡",
        menuItemNumber: 55,
      },
      {
        id: 58,
        name: "Lemon Chicken",
        price: 19.95,
        isSpicy: false,
        chineseTranslation: "柠檬酥鸡",
        menuItemNumber: 56,
      },
      {
        id: 59,
        name: "Chicken in Black Bean Sauce",
        price: 18.95,
        isSpicy: false,
        chineseTranslation: "豉汁鸡",
        menuItemNumber: 57,
      },
      {
        id: 60,
        name: "Crispy Skin Chicken (Half)",
        price: 19.95,
        isSpicy: false,
        chineseTranslation: "脆皮炸汁鸡 (半)",
        menuItemNumber: 58,
      },
      {
        id: 61,
        name: "Crispy Skin Chicken (Whole)",
        price: 36.95,
        isSpicy: false,
        chineseTranslation: "脆皮炸汁 (整)",
        menuItemNumber: 58,
      },
      {
        id: 62,
        name: "BBQ Duck (Half)",
        price: 26.95,
        isSpicy: false,
        chineseTranslation: "明炉烧鸭 (半)",
        menuItemNumber: 59,
      },
      {
        id: 63,
        name: "BBQ Duck (Whole)",
        price: 46.95,
        isSpicy: false,
        chineseTranslation: "明炉烧鸭 (整)",
        menuItemNumber: 59,
      },
      {
        id: 101,
        name: "Peking Duck (Half)",
        price: 45.95,
        isSpicy: false,
        chineseTranslation: "北京片皮鸭 (半)",
        menuItemNumber: 60,
      },
      {
        id: 102,
        name: "Peking Duck (Whole)",
        price: 69.95,
        isSpicy: false,
        chineseTranslation: "北京片皮鸭 (整)",
        menuItemNumber: 60,
      },
      {
        id: 64,
        name: "Sweet and Sour Chicken Balls",
        price: 17.95,
        isSpicy: false,
        chineseTranslation: "酸甜鸡球",
        menuItemNumber: 61,
      },
      {
        id: 65,
        name: "Diced Chicken with Vegetable and Cashew Nuts",
        price: 17.95,
        isSpicy: false,
        chineseTranslation: "腰果鸡丁",
        menuItemNumber: 62,
      },
      {
        id: 66,
        name: "Kung Bo Chicken with Vegetable and Peanuts",
        price: 17.95,
        isSpicy: false,
        chineseTranslation: "宫保鸡丁",
        menuItemNumber: 63,
      },
      {
        id: 67,
        name: "Empress Chicken (Half)",
        price: 24.95,
        isSpicy: false,
        chineseTranslation: "贵妃鸡 (半)",
        menuItemNumber: 64,
      },
      {
        id: 68,
        name: "Empress Chicken (Whole)",
        price: 46.95,
        isSpicy: false,
        chineseTranslation: "贵妃鸡 (整)",
        menuItemNumber: 64,
      },
      {
        id: 69,
        name: "Chicken with Chinese Sausage",
        price: 24.80,
        isSpicy: false,
        chineseTranslation: "啫啫滑鸡煲",
        menuItemNumber: 65,
      },
    ],
  },
  {
    menuSectionName: "SEAFOOD - 海鲜类",
    menuSectionItems: [
      {
        id: 70,
        name: "Sauteed Seafood",
        price: 31.80,
        isSpicy: false,
        chineseTranslation: "油泡海鲜",
        menuItemNumber: 66,
      },
      {
        id: 71,
        name: "Sauteed Scallops",
        price: 28.95,
        isSpicy: false,
        chineseTranslation: "油泡带子",
        menuItemNumber: 67,
      },
      {
        id: 72,
        name: "Curried Prawns",
        price: 24.80,
        isSpicy: true,
        chineseTranslation: "咖喱虾",
        menuItemNumber: 68,
      },
      {
        id: 73,
        name: "Prawn in Black Bean Sauce",
        price: 24.80,
        isSpicy: false,
        chineseTranslation: "豉汁虾球",
        menuItemNumber: 69,
      },
      {
        id: 74,
        name: "Prawn in Cream Sauce",
        price: 26.95,
        isSpicy: false,
        chineseTranslation: "奶油大虾",
        menuItemNumber: 70,
      },
      {
        id: 75,
        name: "Peperry Salt Prawns (with shell)",
        price: 20.75,
        isSpicy: true,
        chineseTranslation: "椒盐中虾 (带壳)",
        menuItemNumber: 71,
      },
      {
        id: 76,
        name: "Peperry Salt Prawns (without shell)",
        price: 22.75,
        isSpicy: true,
        chineseTranslation: "椒盐中虾 (没壳)",
        menuItemNumber: 71,
      },
      {
        id: 77,
        name: "Szechuan Prawn",
        price: 24.95,
        isSpicy: true,
        chineseTranslation: "四川虾球",
        menuItemNumber: 72,
      },
      {
        id: 78,
        name: "Tomato Prawns",
        price: 23.95,
        isSpicy: false,
        chineseTranslation: "番茄虾球",
        menuItemNumber: 73,
      },
      {
        id: 79,
        name: "Deep-Fried Prawns (S)",
        price: 16.95,
        isSpicy: false,
        chineseTranslation: "酥炸虾球 (小)",
        menuItemNumber: 74,
      },
      {
        id: 80,
        name: "Deep-Fried Prawns (L)",
        price: 20.95,
        isSpicy: false,
        chineseTranslation: "酥炸虾球 (大)",
        menuItemNumber: 74,
      },
      {
        id: 81,
        name: "Prawns with Snow Peas",
        price: 24.95,
        isSpicy: false,
        chineseTranslation: "雪豆虾球",
        menuItemNumber: 75,
      },
      {
        id: 82,
        name: "Prawns with Broccoli",
        price: 20.95,
        isSpicy: false,
        chineseTranslation: "百家利虾球",
        menuItemNumber: 76,
      },
      {
        id: 83,
        name: "Pepperry Salt Squid",
        price: 24.95,
        isSpicy: true,
        chineseTranslation: "百家利虾球",
        menuItemNumber: 77,
      },
      {
        id: 84,
        name: "Deep-Fried Squid",
        price: 23.95,
        isSpicy: false,
        chineseTranslation: "酥炸鲜鱿",
        menuItemNumber: 78,
      },
      {
        id: 85,
        name: "Scallops with Vegetables",
        price: 24.95,
        isSpicy: false,
        chineseTranslation: "时菜带子",
        menuItemNumber: 79,
      },
      {
        id: 86,
        name: "Scallops with Snow Peas",
        price: 26.80,
        isSpicy: false,
        chineseTranslation: "雪豆带子",
        menuItemNumber: 80,
      },
      {
        id: 87,
        name: "Scallops with Broccoli",
        price: 23.80,
        isSpicy: false,
        chineseTranslation: "百家利带子",
        menuItemNumber: 81,
      },
      {
        id: 88,
        name: "Squid with Vegetables",
        price: 23.95,
        isSpicy: false,
        chineseTranslation: "时菜鲜鱿",
        menuItemNumber: 82,
      },
      {
        id: 89,
        name: "Deep-Fried Oyster",
        price: 23.95,
        isSpicy: false,
        chineseTranslation: "酥炸生蚝",
        menuItemNumber: 83,
      },
      {
        id: 90,
        name: "Oyster with Ginger and Green Onions",
        price: 26.80,
        isSpicy: false,
        chineseTranslation: "姜葱生蚝",
        menuItemNumber: 84,
      },
      {
        id: 91,
        name: "Rock Cod",
        price: 22.95,
        isSpicy: false,
        chineseTranslation: "各式斑块",
        menuItemNumber: 85,
      },
      // Might need to ask about what the stuff is right now?
      {
        id: 92,
        name: "Fish Filet and Bean Curd in Hot Pot",
        price: 24.95,
        isSpicy: false,
        chineseTranslation: "班球豆腐煲",
        menuItemNumber: 86,
      },
      {
        id: 93,
        name: "Black Cod in Black Bean Sauce with tofu",
        price: 30.80,
        isSpicy: false,
        chineseTranslation: "豉汁豆腐蒸黑鱼",
        menuItemNumber: 87,
      },
      {
        id: 94,
        name: "Peppery Salt Black Fish",
        price: 28.95,
        isSpicy: true,
        chineseTranslation: "椒盐黑鱼",
        menuItemNumber: 88,
      },
      {
        id: 95,
        name: "Seafood and Mixed Vegetables",
        price: 26.95,
        isSpicy: false,
        chineseTranslation: "海鲜杂菜",
        menuItemNumber: 89,
      },
      {
        id: 96,
        name: "Fish Filet in Chili Sauce",
        price: 24.95,
        isSpicy: true,
        chineseTranslation: "水煮鱼片",
        menuItemNumber: 90,
      },
    ],
  },
  {
    menuSectionName: "EGG FOO YUNG AND VEGETABLES - 蔬菜芙蓉类",
    menuSectionItems: [
      {
        id: 97,
        name: "House Special Vegetables",
        price: 20.95,
        isSpicy: false,
        chineseTranslation: "招牌杂菜",
        menuItemNumber: 91,
      },
      {
        id: 98,
        name: "Mixed Vegetables with Oyster Sauce",
        price: 15.95,
        isSpicy: false,
        chineseTranslation: "蚝油四蔬",
        menuItemNumber: 92,
      },
      {
        id: 99,
        name: "Mixed Vegetables with Black Bean Sauce",
        price: 15.95,
        isSpicy: false,
        chineseTranslation: "豉汁四蔬",
        menuItemNumber: 93,
      },
      {
        id: 103,
        name: "Stir-Fried Mixed Vegetables",
        price: 15.95,
        isSpicy: false,
        chineseTranslation: "清炒四蔬",
        menuItemNumber: 94,
      },
      {
        id: 104,
        name: "Mixed Vegetables with Rice Vermicelli Hot Pot",
        price: 18.95,
        isSpicy: false,
        chineseTranslation: "杂菜粉丝煲",
        menuItemNumber: 95,
      },
      {
        id: 105,
        name: "Beef and Mixed Vegetables",
        price: 18.95,
        isSpicy: false,
        chineseTranslation: "牛肉杂菜",
        menuItemNumber: 96,
      },
      {
        id: 106,
        name: "BBQ Pork and Mixed Vegetables",
        price: 16.95,
        isSpicy: false,
        chineseTranslation: "叉烧杂菜",
        menuItemNumber: 97,
      },
      {
        id: 107,
        name: "Minced Pork and Green Bean in Spicy Sauce",
        price: 19.95,
        isSpicy: true,
        chineseTranslation: "干煸四季豆",
        menuItemNumber: 98,
      },
      {
        id: 108,
        name: "Kai Lan in Garlic Sauce",
        price: 18.95,
        isSpicy: false,
        chineseTranslation: "蒜蓉芥籣",
        menuItemNumber: 99,
      },
      {
        id: 109,
        name: "Snow Pea Leaf in Garlic Sauce",
        price: 26.80,
        isSpicy: false,
        chineseTranslation: "蒜蓉豆苗",
        menuItemNumber: 100,
      },
      {
        id: 110,
        name: "Tong Choy in Spicy Bean Curd Sauce",
        price: 24.95,
        isSpicy: true,
        chineseTranslation: "椒丝腐乳通菜",
        menuItemNumber: 101,
      },
      {
        id: 111,
        name: "Egg Plant in Spicy Sauce",
        price: 21.80,
        isSpicy: true,
        chineseTranslation: "鱼香茄子煲",
        menuItemNumber: 102,
      },
      {
        id: 112,
        name: "Buddha's Feast",
        price: 17.80,
        isSpicy: false,
        chineseTranslation: "罗汉上素",
        menuItemNumber: 103,
      },
      {
        id: 113,
        name: "Beef and Bitter Melon in Black Bean Sauce",
        price: 23.80,
        isSpicy: false,
        chineseTranslation: "豉汁凉瓜牛肉",
        menuItemNumber: 104,
      },
      {
        id: 114,
        name: "Chinese Mushrooms with Vegetables",
        price: 21.95,
        isSpicy: false,
        chineseTranslation: "北菇扒菜贍",
        menuItemNumber: 105,
      },
      {
        id: 115,
        name: "Shrimp Egg Foo Yung",
        price: 20.95,
        isSpicy: false,
        chineseTranslation: "虾芙蓉",
        menuItemNumber: 106,
      },
      {
        id: 116,
        name: "House Special Egg Foo Yung",
        price: 18.95,
        isSpicy: false,
        chineseTranslation: "招牌芙蓉",
        menuItemNumber: 107,
      },
      {
        id: 117,
        name: "BBQ Pork Egg Foo Yung",
        price: 17.95,
        isSpicy: false,
        chineseTranslation: "叉烧芙蓉",
        menuItemNumber: 108,
      },
      {
        id: 118,
        name: "Chicken Egg Foo Yung",
        price: 17.95,
        isSpicy: false,
        chineseTranslation: "鸡芙蓉",
        menuItemNumber: 109,
      },
      {
        id: 119,
        name: "Mushroom Egg Foo Yung",
        price: 15.95,
        isSpicy: false,
        chineseTranslation: "蘑菇芙蓉",
        menuItemNumber: 110,
      },
    ],
  },
  {
    menuSectionName: "TOFU - 豆腐类",
    menuSectionItems: [
      {
        id: 120,
        name: "Ma Pao Tofu",
        price: 18.95,
        isSpicy: true,
        chineseTranslation: "麻婆豆腐",
        menuItemNumber: 111,
      },
      {
        id: 121,
        name: "Braised Tofu with Chinese Mushrooms",
        price: 18.95,
        isSpicy: false,
        chineseTranslation: "红烧豆腐",
        menuItemNumber: 112,
      },
      {
        id: 122,
        name: "Deep-Fried Tofu with Peppery Salt",
        price: 16.95,
        isSpicy: true,
        chineseTranslation: "椒盐豆腐",
        menuItemNumber: 113,
      },
      {
        id: 123,
        name: "Assorted Seafood and Meat with Tofu Hot Pot",
        price: 25.80,
        isSpicy: false,
        chineseTranslation: "八珍豆腐煲",
        menuItemNumber: 114,
      },
      {
        id: 124,
        name: "Diced Chicken and Tofu with Salty Fish Hot Pot",
        price: 20.80,
        isSpicy: false,
        chineseTranslation: "咸鱼鸡粒豆腐煲",
        menuItemNumber: 115,
      },
      {
        id: 125,
        name: "Egg Tofu with Enoki",
        price: 19.95,
        isSpicy: false,
        chineseTranslation: "金菇玉子豆腐煲",
        menuItemNumber: 116,
      },
    ],
  },
  {
    menuSectionName: "LIVE LOBSTER & LIVE BC CRAB - 生猛游水龙虾&螃蟹，市价",
    menuSectionItems: [
      {
        id: 126,
        name:
          "Peppery Salt, Ginger & Green Onion, Black Bean Sauce, Cream Sauce, Chili Spicy, House Special, Salty Egg Yolk Sauce",
        price: -1,
        isSpicy: false,
        chineseTranslation: "椒盐，葱姜，豉汁，奶油，避风塘，秘制，金沙",
        menuItemNumber: 116,
      },
    ],
  },
  {
    menuSectionName: "FRIED RICE - 炒饭类",
    menuSectionItems: [
      {
        id: 127,
        name: "New King Do Fried Rice",
        price: 18.50,
        isSpicy: false,
        chineseTranslation: "新京都炒饭",
        menuItemNumber: 117,
      },
      {
        id: 128,
        name: "Seafood-Fried Rice",
        price: 20.95,
        isSpicy: false,
        chineseTranslation: "海鲜炒饭",
        menuItemNumber: 118,
      },
      {
        id: 129,
        name: "Honeymoon Fried Rice",
        price: 19.75,
        isSpicy: false,
        chineseTranslation: "鸳鸯炒饭",
        menuItemNumber: 119,
      },
      {
        id: 130,
        name: "Cantonese-Style Fried Rice",
        price: 23.95,
        isSpicy: false,
        chineseTranslation: "广东炒饭",
        menuItemNumber: 120,
      },
      {
        id: 131,
        name: "Chicken-Fried Rice",
        price: 16.80,
        isSpicy: false,
        chineseTranslation: "鸡炒饭",
        menuItemNumber: 121,
      },
      {
        id: 132,
        name: "BBQ Pork-Fried Rice",
        price: 16.95,
        isSpicy: false,
        chineseTranslation: "叉烧炒饭",
        menuItemNumber: 122,
      },
      {
        id: 133,
        name: "Dried Scallops with Egg White Fried Rice",
        price: 26.80,
        isSpicy: false,
        chineseTranslation: "瑶柱蛋白炒饭",
        menuItemNumber: 123,
      },
      {
        id: 134,
        name: "Prawn-Fried Rice",
        price: 18.95,
        isSpicy: false,
        chineseTranslation: "虾炒饭",
        menuItemNumber: 124,
      },
      {
        id: 135,
        name: "Mushroom-Fried Rice",
        price: 14.95,
        isSpicy: false,
        chineseTranslation: "蘑菇炒饭",
        menuItemNumber: 125,
      },
      {
        id: 136,
        name: "Vegetable-Fried Rice",
        price: 14.95,
        isSpicy: false,
        chineseTranslation: "杂菜炒饭",
        menuItemNumber: 126,
      },
      {
        id: 137,
        name: "Fok Chow Fried Rice",
        price: 20.95,
        isSpicy: false,
        chineseTranslation: "福州炒饭",
        menuItemNumber: 127,
      },
      {
        id: 138,
        name: "Diced Chicken and Salty Fish Fried Rice",
        price: 26.80,
        isSpicy: false,
        chineseTranslation: "咸鱼鸡粒炒饭",
        menuItemNumber: 128,
      },
      {
        id: 139,
        name: "Prawn and Scramble Egg Fried Rice",
        price: 23.95,
        isSpicy: false,
        chineseTranslation: "滑蛋虾球炒饭",
        menuItemNumber: 129,
      },
      {
        id: 140,
        name: "Beef and Tomato on Rice",
        price: 18.95,
        isSpicy: false,
        chineseTranslation: "番茄牛肉炒饭",
        menuItemNumber: 130,
      },
      {
        id: 141,
        name: "Curry Chicken on Rice",
        price: 17.95,
        isSpicy: true,
        chineseTranslation: "咖喱鸡饭",
        menuItemNumber: 131,
      },
      {
        id: 142,
        name: "Curry Beef on Rice",
        price: 17.95,
        isSpicy: true,
        chineseTranslation: "咖喱牛肉饭",
        menuItemNumber: 132,
      },
      {
        id: 143,
        name: "Beef-Fried Rice",
        price: 18.95,
        isSpicy: false,
        chineseTranslation: "生炒牛饭",
        menuItemNumber: 133,
      },
      {
        id: 144,
        name: "Yang Chow Fried Rice",
        price: 23.80,
        isSpicy: false,
        chineseTranslation: "扬州炒饭",
        menuItemNumber: 134,
      },
    ],
  },
  {
    menuSectionName: "FRIED NOODLES - CHOW MEIN - 炒粉面类",
    menuSectionItems: [
      {
        id: 145,
        name: "Chicken-Fried Noodles",
        price: 16.95,
        isSpicy: false,
        chineseTranslation: "鸡炒面",
        menuItemNumber: 135,
      },
      {
        id: 146,
        name: "BBQ Pork-Fried Noodles",
        price: 17.95,
        isSpicy: false,
        chineseTranslation: "叉烧炒面",
        menuItemNumber: 136,
      },
      {
        id: 147,
        name: "House Special Fried Noodles",
        price: 21.95,
        isSpicy: false,
        chineseTranslation: "新京都炒面",
        menuItemNumber: 137,
      },
      {
        id: 148,
        name: "Beef-Fried Noodles",
        price: 17.50,
        isSpicy: false,
        chineseTranslation: "牛肉炒面",
        menuItemNumber: 138,
      },
      {
        id: 149,
        name: "Shrimp-Fried Noodles",
        price: 18.95,
        isSpicy: false,
        chineseTranslation: "虾仁炒面",
        menuItemNumber: 139,
      },
      {
        id: 150,
        name: "Mushroom-Fried Noodles",
        price: 15.95,
        isSpicy: false,
        chineseTranslation: "蘑菇炒面",
        menuItemNumber: 140,
      },
      {
        id: 151,
        name: "Vegetable-Fried Noodles",
        price: 15.95,
        isSpicy: false,
        chineseTranslation: "杂菜炒面",
        menuItemNumber: 141,
      },
      {
        id: 152,
        name: "Beef and Tomato-Fried Noodles",
        price: 19.95,
        isSpicy: false,
        chineseTranslation: "番茄牛肉面",
        menuItemNumber: 142,
      },
      {
        id: 153,
        name: "Curry Beef-Fried Noodles",
        price: 18.95,
        isSpicy: false,
        chineseTranslation: "咖喱牛肉面",
        menuItemNumber: 143,
      },
      {
        id: 154,
        name: "Curry Chicken-Fried Noodles",
        price: 18.95,
        isSpicy: false,
        chineseTranslation: "咖喱鸡面",
        menuItemNumber: 144,
      },
      {
        id: 155,
        name: "Shredded Pork-Fried Noodles (Crispy)",
        price: 17.50,
        isSpicy: false,
        chineseTranslation: "肉丝脆面",
        menuItemNumber: 145,
      },
      {
        id: 156,
        name: "Shredded Chicken-Fried Noodles (Crispy)",
        price: 17.95,
        isSpicy: false,
        chineseTranslation: "鸡丝脆面",
        menuItemNumber: 146,
      },
      {
        id: 157,
        name: "Cantonese-Style Fried Noodles",
        price: 24.95,
        isSpicy: false,
        chineseTranslation: "广东炒面",
        menuItemNumber: 147,
      },
      {
        id: 158,
        name: "Seafood-Fried Noodles (Crispy)",
        price: 24.95,
        isSpicy: false,
        chineseTranslation: "海鲜脆面",
        menuItemNumber: 148,
      },
      {
        id: 159,
        name: "Buddha's Feast Fried Noodles (Crispy)",
        price: 18.95,
        isSpicy: false,
        chineseTranslation: "罗汉斋脆面",
        menuItemNumber: 149,
      },
      {
        id: 160,
        name: "Pan-Fried Braised E-Fu",
        price: 18.95,
        isSpicy: false,
        chineseTranslation: "干烧伊面",
        menuItemNumber: 150,
      },
      {
        id: 161,
        name: "Prawn-Fried Noodles (Crispy)",
        price: 23.95,
        isSpicy: false,
        chineseTranslation: "虾球脆面",
        menuItemNumber: 151,
      },
      {
        id: 162,
        name: "Singapore-Style Fried Vermicelli",
        price: 19.95,
        isSpicy: false,
        chineseTranslation: "星洲炒米",
        menuItemNumber: 152,
      },
      {
        id: 163,
        name: "Pan-Fried Rice Noodles with Beef",
        price: 24.95,
        isSpicy: false,
        chineseTranslation: "干炒牛河",
        menuItemNumber: 153,
      },
      {
        id: 164,
        name: "Pan-Fried Rice Noodles with Beef and Vegetables",
        price: 25.95,
        isSpicy: false,
        chineseTranslation: "菜远牛河",
        menuItemNumber: 154,
      },
      {
        id: 165,
        name: "Rice Noodles with Beef in Black Bean Sauce",
        price: 24.95,
        isSpicy: false,
        chineseTranslation: "豉椒牛河",
        menuItemNumber: 155,
      },
      {
        id: 166,
        name: "Rice Noodles with Prawns and Scramble Egg",
        price: 26.95,
        isSpicy: false,
        chineseTranslation: "滑蛋虾球河",
        menuItemNumber: 156,
      },
      {
        id: 167,
        name: "Stir-Fried Rice Noodles in Spicy Sauce",
        price: 22.95,
        isSpicy: true,
        chineseTranslation: "炒贵刁",
        menuItemNumber: 157,
      },
    ],
  },
  {
    menuSectionName: "SPECIAL COMBO & FAMILY DINNERS - 特别组合 & 家庭晚餐",
    menuSectionItems: [
      {
        id: 168,
        name:
          "Three Items Combo For One - Chicken Chow Mein, BBQ, Pork Fried Rice, Sweet & Sour Pork, Beef & Broccoli.",
        price: 16.95,
        isSpicy: false,
        chineseTranslation:
          "三样组合 (一人食) - 鸡炒面，叉烧炒饭，咕噜肉，百家利牛肉",
        menuItemNumber: 0,
      },
      {
        id: 169,
        name:
          "Four Items Combo For One - Chicken Chow Mein, BBQ, Pork Fried Rice, Sweet & Sour Pork, Beef & Broccoli.",
        price: 17.95,
        isSpicy: false,
        chineseTranslation:
          "四样组合 (一人食) - 鸡炒面，叉烧炒饭，咕噜肉，百家利牛肉",
        menuItemNumber: 0,
      },
      {
        id: 170,
        name:
          "For Two (A) - Two egg rolls, sweet & sour pork, beef chop suey, and chicken chow mein.",
        price: 42.95,
        isSpicy: false,
        chineseTranslation: "两人套餐 - 两个素春卷，咕噜肉，牛肉杂菜，鸡炒面",
        menuItemNumber: 0,
      },
      {
        id: 171,
        name:
          "For Two (B) - Two egg rolls, sweet & sour pork, beef and broccoli, and BBQ pork-fried rice.",
        price: 43.95,
        isSpicy: false,
        chineseTranslation:
          "两人套餐 - 两个素春卷，咕噜肉，百家利牛肉，叉烧炒饭",
        menuItemNumber: 0,
      },
      {
        id: 172,
        name:
          "For Three (A) - Three egg rolls, sweet & sour pork, beef and broccoli, small deep-fried breaded chicken, and BBQ pork-fried rice.",
        price: 58.95,
        isSpicy: false,
        chineseTranslation:
          "三人套餐 - 三个素春卷，咕噜肉，百家利牛肉，小份酥鸡，叉烧炒饭",
        menuItemNumber: 0,
      },
      {
        id: 173,
        name:
          "For Three (B) - Three egg rolls, sweet & sour pork, beef chop suey, chicken fried noodles, and 12 pieces of deep-fried prawns.",
        price: 60.95,
        isSpicy: false,
        chineseTranslation:
          "三人套餐 - 三个素春卷，咕噜肉，牛肉杂菜，鸡炒面，12块油炸大虾",
        menuItemNumber: 0,
      },
      {
        id: 174,
        name:
          "For Four (A) - Four egg rolls, sweet & sour pork, wonton soup(8), large deep-fried breaded chicken, beef chop suey, BBQ pork fried-rice, and chicken chow mein.",
        price: 90.95,
        isSpicy: false,
        chineseTranslation:
          "四人套餐 - 四个素春卷，咕噜肉，云吞汤，大份酥鸡，牛肉杂菜，叉烧炒粉，鸡炒面",
        menuItemNumber: 0,
      },
      {
        id: 175,
        name:
          "For Four (B) - Four egg rolls, honey pork, wonton soup(8), 16 pcs deep-fried prawns, beef and broccoli, BBQ pork-fried rice, and chicken chow mein.",
        price: 92.95,
        isSpicy: false,
        chineseTranslation:
          "四人套餐 - 四个素春卷，蜜汁猪，云吞汤，16块炸大虾，百家利牛肉，叉烧炒饭，鸡炒面",
        menuItemNumber: 0,
      },
      {
        id: 176,
        name:
          "For Six (A) - Six egg rolls, sweet & sour pork, large deep-fried prawns, lemon chicken, beef chop suey, house special fried-rice, and house special chow mein.",
        price: 116.95,
        isSpicy: false,
        chineseTranslation:
          "六人套餐 - 六个素春卷，咕噜肉，炸虾，柠檬鸡，牛肉杂菜，新京都炒面，新京都炒面",
        menuItemNumber: 0,
      },
      {
        id: 177,
        name:
          "For Six (B) - Six egg rolls, honey pork, deep-fried breaded chicken, curry prawns, beef and broccoli, yang chow fried rice, and house special chow mein.",
        price: 120.95,
        isSpicy: false,
        chineseTranslation:
          "六人套餐 - 六个素春卷，蜜汁猪，酥鸡，咖喱虾，百家利牛肉，扬州炒饭，新京都炒面",
        menuItemNumber: 0,
      },
      {
        id: 178,
        name:
          "For Eight - Eight egg rolls, honey pork, curry prawns, sweet & sour chicken, beef chop suey, stir-fried shredded beef tenderloin, chicken in black bean sauce, king do fried rice, house special chow mein.",
        price: 168.95,
        isSpicy: false,
        chineseTranslation:
          "八人套餐 - 八个素春卷，蜜汁猪，咖喱虾，甜酸鸡，牛肉杂菜，中式牛柳，豉汁鸡，京都炒饭，招牌炒面",
        menuItemNumber: 0,
      },
    ],
  },
  {
    menuSectionName: "CHEF RECOMMENDATION - 厨师推荐",
    menuSectionItems: [
      {
        id: 179,
        name: "Lamb hotpot",
        price: 38.80,
        isSpicy: true,
        chineseTranslation: "枝竹羊腩煲",
        menuItemNumber: 158,
      },
      {
        id: 180,
        name: "Braised Pork Belly",
        price: 22.80,
        isSpicy: false,
        chineseTranslation: "东坡肉",
        menuItemNumber: 159,
      },
      {
        id: 181,
        name: "Black Pepper Pork Tripe Soup",
        price: 18.80,
        isSpicy: true,
        chineseTranslation: "胡椒猪肚煲",
        menuItemNumber: 160,
      },
      {
        id: 182,
        name: "Braised Pig's Trotters with Fermented Red Beancurd",
        price: 19.80,
        isSpicy: false,
        chineseTranslation: "南乳猪手",
        menuItemNumber: 161,
      },
      {
        id: 183,
        name: "Braised Beef Brisket with Radish Hotpot",
        price: 24.80,
        isSpicy: false,
        chineseTranslation: "萝卜牛腩煲",
        menuItemNumber: 162,
      },
    ],
  },
];
